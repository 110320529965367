<template>
  <b-row>
    <b-col md="12">
      <b-card title="Filtrele">
        <service-date-picker-card />
        <b-row>
          <b-col>
            <customer-select-cards />
          </b-col>
          <b-col>
            <service-report-machines-card />
          </b-col>
        </b-row>
        <service-report-machine-info-card />
        <control-criteries-cards />
        <div class="text-center">
          <b-button
            variant="primary"
            :disabled="!filterData.id_machines"
            @click="getReports"
          >
            <FeatherIcon icon="PieChartIcon" />
            Verileri Getir

          </b-button>
        </div>
      </b-card>
      <report-istatisticts-card />
      <template v-if="dataList.reportData">
        <b-card
          v-if="dataList.reportData.series"
          title="Rapor Detayları"
          no-body
        >
          <b-card-header>
            <b-card-title>
              Rapor Detayları
            </b-card-title>
            <b-button
              variant="success"
              :href="downloadUrl"
              target="_blank"
            ><FeatherIcon icon="DownloadIcon" /> Raporu İndir</b-button>
          </b-card-header>

          <b-table-simple
            striped
            hover
            responsive
            bordered
            caption-top
            caption-bottom
          >
            <b-thead>
              <b-tr>
                <b-th class="align-middle b-table-sticky-column">
                  Numune Bilgileri
                </b-th>
                <b-th class="align-middle text-center">
                  Test Metodu
                </b-th>

                <b-th
                  v-for="(date, index) in dataList.reportData.dates"
                  :key="index"
                  class="align-middle"
                >
                  <div class="text-center">
                    {{ index+1 }}
                  </div>
                  <div class="text-center">
                    {{ moment(date).format('DD.MM.YYYY') }}
                  </div>
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <template v-if="CriteriesData.criteries.length > 0">
                <template v-for="(criter) in criteriesList">
                  <table-row-card
                    v-if="CriteriesData.criteries.includes(criter.id)"
                    :key="criter.id"
                    :row-title="criter.title"
                    :test-method="criter.testmethod"
                    :row-data="dataList.reportData.series[criter.referance]"
                    :data-type="criter.referance"
                    :units-data="criter.units"
                  />
                </template>
              </template>
              <template v-else>
                <table-row-card
                  v-for="(criter, index) in criteriesList"
                  :key="index"
                  :row-title="criter.title"
                  :test-method="criter.testmethod"
                  :row-data="dataList.reportData.series[criter.referance]"
                  :data-type="criter.referance"
                  :units-data="criter.units"
                />
              </template>

            </b-tbody>
          </b-table-simple>

        </b-card>

        <b-alert
          v-else
          variant="info"
          show
        >
          <div class="alert-body text-center">
            <feather-icon
              icon="InfoIcon"
              size="21"
            />

            Belirtilen tarih aralığında gösterilecek kayıt bulunmuyor.
          </div>
        </b-alert>
      </template>
      <template v-if="dataList.chartData ">
        <chart-cards
          v-for="(chart , index) in dataList.chartData"
          :key="index"
          :chart-data="chart"
        />
      </template>
    </b-col>
  </b-row>

</template>

<script>
import {
  BButton,
  BCard, BCol,
  BRow, BTh, BTr,
  BTableSimple,
  BCardHeader,
  BCardTitle,
  BThead,
  BTbody,
  VBPopover, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ServiceDatePickerCard from '@/views/Reports/Service_reports/Components/DatePickerCard.vue'
import CustomerSelectCards from '@/views/Reports/Service_reports/Components/CustomerSelectCard.vue'
import ServiceReportMachinesCard from '@/views/Reports/Service_reports/Components/MachinesCard.vue'
import ServiceReportMachineInfoCard from '@/views/Reports/Service_reports/Components/MachineCard.vue'
import ControlCriteriesCards from '@/views/Reports/Service_reports/Components/ControlCriterionsCards.vue'
import TableRowCard from '@/views/Reports/Service_reports/Components/TableRowCard.vue'
import ChartCards from '@/views/Reports/Service_reports/Components/Charts.vue'
import ReportIstatistictsCard from '@/views/Reports/Service_reports/Components/ReportIstatistics.vue'

export default {
  name: 'ServiceReports',
  components: {
    BAlert,
    ReportIstatistictsCard,
    ChartCards,
    TableRowCard,
    BTh,
    BButton,
    ControlCriteriesCards,
    ServiceReportMachineInfoCard,
    ServiceReportMachinesCard,
    CustomerSelectCards,
    ServiceDatePickerCard,
    BCol,
    BCard,
    BRow,
    BTableSimple,
    BThead,
    BTr,
    BCardHeader,
    BCardTitle,
    BTbody,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {

    }
  },

  computed: {
    dataList() {
      return this.$store.getters['serviceReports/dataList']
    },
    filterData() {
      return this.$store.getters['serviceReports/filterData']
    },
    CriteriesData() {
      return this.$store.getters['serviceReports/CriteriesData']
    },
    criteriesList() {
      return this.$store.getters['serviceReports/CriteriesList']
    },
    downloadUrl() {
      return `${this.$store.state.app.baseURL}/exports/reports/Service_reports/excel?token=${localStorage.getItem('downloadToken')}&sdate=${this.filterData.sdate}&edate=${this.filterData.edate}&id_customers=${this.filterData.id_customers}&id_machines=${this.filterData.id_machines}`
    },
  },
  created() {
    this.resetForm()
    this.resetReport()
  },
  methods: {
    getReports() {
      return this.$store.dispatch('serviceReports/setReports', this.filterData)
    },
    resetForm() {
      return this.$store.dispatch('serviceReports/resetFilterData')
    },
    resetReport() {
      return this.$store.dispatch('serviceReports/resetReportData')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
