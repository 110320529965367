<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group
          label="Firma Seçimi"
          label-for="id_customers"
        >
          <v-select
            id="id_customers"
            v-model="id_customers"
            name="id_customers"
            :options="customerList"
            :reduce="customer => customer.id"
            label="company"
            placeholder="Firma Seçimi"
            :disabled="!filterData.sdate || !filterData.edate"
          />
        </b-form-group>
      </b-col>

    </b-row>

  </div>
</template>

<script>
import {
  BFormGroup, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'CustomerSelectCards',
  components: {
    vSelect,
    BFormGroup,
    BRow,
    BCol,
  },
  data() {
    return {
      locale: 'tr',
      id_customers: null,
    }
  },
  computed: {
    filterData() {
      return this.$store.getters['serviceReports/filterData']
    },
    customerList() {
      return this.$store.getters['customers/getCustomers']
    },
    serviceList() {
      return this.$store.getters['services/getServicesCount']
    },
    requestList() {
      return this.$store.getters['serviceRequests/getService_requestsCount']
    },
    totalIstatistics() {
      return this.$store.getters['serviceReports/totalIstatistics']
    },
  },
  watch: {
    id_customers(val) {
      this.filterData.id_customers = val
      this.$store.dispatch('machines/resetDataItem')
      this.filterData.id_machines = null
      if (val) {
        this.getMachines(val)
        this.setIstatistics(val)
      }
    },
  },
  created() {
    this.getCustomers()
  },
  methods: {
    getCustomers() {
      return this.$store.dispatch('customers/customersList')
    },
    getMachines(val) {
      return this.$store.dispatch('machines/machinesList', {
        where: {
          'machines.id_customers': val,
        },
      })
    },
    setIstatistics(val) {
      return this.$store.dispatch('serviceReports/setIstatistics', {
        id_customers: val,
      })
    },
  },
}
</script>
<style scoped></style>
