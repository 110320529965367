<template>
  <div>
    <vue-apex-charts
      type="line"
      :height="height"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  name: 'LineChart',
  components: {
    VueApexCharts,
  },
  props: [
    'dataLabels',
    'dataSeries',
    'height',
  ],
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          zoom: {
            enabled: true,
          },
          toolbar: {
            show: false,
          },
        },
        markers: {
          strokeWidth: 7,
          strokeOpacity: 1,
          strokeColors: [$themeColors.light],
          colors: [$themeColors.warning],
        },
        colors: [$themeColors.primary, $themeColors.warning],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'straight',
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        tooltip: {
          custom(data) {
            return `${'<div class="px-1 py-50"><span>'}${
              data.series[data.seriesIndex][data.dataPointIndex]
            }</span></div>`
          },
        },
        xaxis: {
          categories: [],
        },
      },
    }
  },
  watch: {
    dataSeries(val) {
      if (val) {
        this.setChartData()
      }
    },
  },
  created() {
    this.setChartData()
  },
  methods: {
    setChartData() {
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: this.dataLabels,
          },
        },
      }
      this.series = this.dataSeries
    },
  },
}
</script>

<style scoped>

</style>
