<template>
  <div>
    <b-row
      v-if="filterData.id_machines"
      class="mb-2"
    >
      <b-col>
        <b-list-group>
          <b-list-group-item>
            <div class="text-primary font-weight-bolder">
              Proje Numarası
            </div>
            <div>{{ machine.project ? machine.project : '-' }}</div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="text-primary font-weight-bolder">
              Makine Kodu
            </div>
            <div>{{ machine.mcode ? machine.mcode : '-' }}</div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="text-primary font-weight-bolder">
              Fabrika / Seri No
            </div>
            <div>{{ machine.serial ? machine.serial : '-' }}</div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="text-primary font-weight-bolder">
              Kurulum Yılı
            </div>
            <div>{{ machine.syear ? machine.syear : '-' }}</div>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col>
        <b-list-group>
          <b-list-group-item>
            <div class="text-primary font-weight-bolder">
              Makine Tipi
            </div>
            <div>{{ machine.machine_type ? machine.machine_type : '-' }}</div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="text-primary font-weight-bolder">
              Makine Modeli
            </div>
            <div>{{ machine.machine_model ? machine.machine_model : '-' }}</div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="text-primary font-weight-bolder">
              Temizleme Teknolojisi
            </div>
            <div>{{ machine.machine_cleaning ? machine.machine_cleaning : '-' }}</div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="text-primary font-weight-bolder">
              Makine Lokasyonu
            </div>
            <div>{{ machine.customer_address ? machine.customer_address : '-' }}</div>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col>
        <b-list-group>
          <b-list-group-item>
            <div class="text-primary font-weight-bolder">
              Servis Adeti <span class="text-muted">(Seçilen Tarihler Arası)</span>
            </div>
            <div>{{ serviceCount > 0 ? serviceCount : '-' }}</div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="text-primary font-weight-bolder">
              Servis Talep Adeti <span class="text-muted">(Seçilen Tarihler Arası)</span>
            </div>
            <div>{{ RequestCount > 0 ? RequestCount : '-' }}</div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="text-primary font-weight-bolder">
              Kayıt Eden
            </div>
            <div>{{ machine.username ? machine.username : '-' }}</div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="text-primary font-weight-bolder">
              Makine Durumu
            </div>
            <div>{{ machine.machine_status ? machine.machine_status : '-' }}</div>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BListGroup, BListGroupItem,
} from 'bootstrap-vue'

export default {
  name: 'ServiceReportMachineInfoCard',
  components: {
    BListGroup, BListGroupItem, BRow, BCol,
  },
  data() {
    return {
      locale: 'tr',
      id_machines: null,
    }
  },
  computed: {
    machine() {
      return this.$store.getters['machines/getMachine']
    },
    filterData() {
      return this.$store.getters['serviceReports/filterData']
    },
    serviceCount() {
      return this.$store.getters['services/getServices']
    },
    RequestCount() {
      return this.$store.getters['serviceRequests/getService_requests']
    },
  },

}
</script>
<style scoped></style>
