<template>
  <div>
    <b-form-group
      label="Makine Kartı Seçimi"
      label-for="id_machines"
    >
      <v-select
        id="id_machines"
        v-model="filterData.id_machines"
        name="id_machines"
        :options="machineList"
        :reduce="machine => machine.id"
        label="project"
        placeholder="Makine Kartı Seçimi"
        :disabled="!filterData.id_customers"
      >
        <template v-slot:option="option">
          <div>
            {{ option.project }}
            <small class="text-warning">{{ option.mcode }}</small>
          </div>
        </template>
        <template v-slot:selected-option="option">
          <div>
            {{ option.project }}
            <small class="text-warning">{{ option.mcode }}</small>
          </div>
        </template>
      </v-select>
    </b-form-group>
  </div>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'ServiceReportMachinesCard',
  components: {
    vSelect,
    BFormGroup,
  },
  data() {
    return {
      locale: 'tr',
      id_machines: null,
    }
  },
  computed: {
    filterData() {
      return this.$store.getters['serviceReports/filterData']
    },
    machineList() {
      return this.$store.getters['machines/getMachines']
    },
    machine() {
      return this.$store.getters['machines/getMachine']
    },
  },
  watch: {
    filterData: {
      deep: true,
      handler(val) {
        if (val.id_machines) {
          this.getMachine(val.id_machines)
          this.totalServices(val.id_machines)
          this.totalServiceRequests(val.id_machines)
        }
      },
    },
  },
  methods: {
    getMachine(val) {
      return this.$store.dispatch('machines/machineView', val)
    },
    totalServices(id) {
      return this.$store.dispatch('services/servicesList', {
        where: {
          'services.id_machines': id,
          'services.created >=': this.filterData.sdate,
          'DATE(services.created) <=': this.filterData.edate,
        },
        dataCount: true,
      })
    },
    totalServiceRequests(id) {
      return this.$store.dispatch('serviceRequests/service_requestsList', {
        where: {
          'service_requests.id_machines': id,
          'service_requests.created >=': this.filterData.sdate,
          'DATE(service_requests.created) <=': this.filterData.edate,
        },
        dataCount: true,
      })
    },
  },
}
</script>
<style scoped></style>
