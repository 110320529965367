<template>

  <b-row>
    <b-col>
      <b-card
        v-if="chartData"
        no-body
      >
        <b-card-header>
          <b-card-title>{{ chartData.series[0].name }}</b-card-title>
        </b-card-header>
        <b-card-text :id="chartData.series[0].name">
          <line-chart
            :data-labels="chartData.categories"
            :data-series="chartData.series"
            height="300"
          />
        </b-card-text>
      </b-card>
    </b-col>
  </b-row>

</template>

<script>
import {
  BCard, BCol,
  BCardHeader, BCardText, BCardTitle, BRow,

} from 'bootstrap-vue'
import LineChart from '@/views/Services/Components/LineChart.vue'

export default {
  name: 'ChartCards',
  components: {
    BCard, BCardText, BRow, BCardTitle, BCardHeader, LineChart, BCol,

  },
  props: {
    chartData: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      locale: 'tr',
    }
  },
}
</script>
<style scoped></style>
