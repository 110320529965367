<template>
  <div>
    <b-form-group
      label="Kontrol Kriterleri Seçimi"
      label-for="criteries"
    >
      <v-select
        id="criteries"
        v-model="CriteriesData.criteries"
        name="criteries"
        :options="criteriesList"
        :reduce="criter => criter.id"
        label="title"
        placeholder="Tümü"
        :disabled="!filterData.id_machines"
        multiple
        :close-on-select="false"
      />
    </b-form-group>
  </div>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'ControlCriteriesCards',
  components: {
    vSelect,
    BFormGroup,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    CriteriesData() {
      return this.$store.getters['serviceReports/CriteriesData']
    },
    filterData() {
      return this.$store.getters['serviceReports/filterData']
    },
    criteriesList() {
      return this.$store.getters['serviceReports/CriteriesList']
    },
  },
}
</script>
<style scoped></style>
