import { render, staticRenderFns } from "./CustomerSelectCard.vue?vue&type=template&id=638f35ba&scoped=true&"
import script from "./CustomerSelectCard.vue?vue&type=script&lang=js&"
export * from "./CustomerSelectCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "638f35ba",
  null
  
)

export default component.exports