<template>
  <b-tr class="text-center">
    <b-td class="text-left b-table-sticky-column bg-white border-right">
      {{ rowTitle }}
    </b-td>
    <b-td>{{ testMethod }}</b-td>
    <b-td
      v-for="(dataItem, index) in rowData"
      :key="index"
    >
      <template v-if="dataType === 'analysed'">
        {{ moment(dataItem).format('DD.MM.YYYY') }}
      </template>
      <template v-else-if="dataType === 'general_situation'">
        <general-situation :id="Number(dataItem)" />
      </template>
      <template v-else-if="dataType === 'refractometer'">
        {{ dataItem ? '%' + dataItem : '-' }}
      </template>
      <template v-else-if="unitsData">
        {{ dataItem ? dataItem +' '+ unitsData : '-' }}
      </template>
      <template v-else>
        {{ dataItem ? dataItem : '-' }}
      </template>

    </b-td>
  </b-tr>
</template>

<script>
import {
  BTr,
  BTd,
} from 'bootstrap-vue'
import GeneralSituation from '@/views/Services/Components/GeneralSituation.vue'

export default {
  name: 'TableRowCard',
  components: {
    GeneralSituation,
    BTr,
    BTd,
  },
  props: {
    rowTitle: {
      type: String,
      require: true,
    },
    testMethod: {
      type: String,
      default: null,
    },
    rowData: {
      type: Array,
      require: true,
    },
    unitsData: {
      type: String,
      default: null,
    },
    dataType: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      locale: 'tr',

    }
  },
}
</script>
<style scoped></style>
