<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group
          label="Başlangıç Tarihi"
          label-for="sdate"
        >
          <b-form-datepicker
            id="sdate"
            v-model="filterData.sdate"
            v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Bitiş Tarihi"
          label-for="edate"
        >
          <b-form-datepicker
            id="edate"
            v-model="filterData.edate"
            v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
          />
        </b-form-group>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BFormGroup, BFormDatepicker, BRow, BCol,
} from 'bootstrap-vue'

export default {
  name: 'ServiceDatePickerCard',
  components: {
    BFormGroup,
    BFormDatepicker,
    BRow,
    BCol,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    filterData() {
      return this.$store.getters['serviceReports/filterData']
    },
  },
}
</script>
<style scoped></style>
